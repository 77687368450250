import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
import "./css/bootstrap.css";
import "./css/style.css";


ReactDOM.render(
  <Main/>, 
  document.getElementById("root")
);