import React, { Component } from "react";
import welcome from "./images/welcome.jfif"
import {
    Route,
    NavLink,
    HashRouter
  } from "react-router-dom";


const frame = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2505.8898622790525!2d6.321010416130197!3d51.09204007956905!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf560aa5c573a9%3A0x5bd3096cb152f25c!2sDipl.-Psych.%20Andreas%20Krause!5e0!3m2!1sen!2snl!4v1585053230532!5m2!1sen!2snl" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>';

class Home extends Component {


iframe = (frame) => {
    return {
        __html: frame
    }

} 

    render() {
        return(
        <HashRouter>
            <div>
                <div className="wrapHome">
                    <div className="description">
                        <h2 className="Headline">Willkommen auf der Webseite der Psychotherapeutischen Praxis von Andreas Krause</h2>
                        <p> Ich freue mich sehr, Sie auf meiner Webseite begrüßen zu dürfen.
                            Als niedergelassener Psychotherapeut in Erkelenz im Kreis Heinsberg
                            biete ich neben klassischer Verhaltenstherapie auch Coachings sowie
                            die Diagnostik unterschiedlicher Krankheitsbilder an.
                            Über das Seiten-Menü finden Sie meine Kontaktinformationen
                            und können mehr über mich und meine Tätigkeit in Erfahrung bringen.</p>
                        
                        <h4>Die Praxis</h4>
                        <p> Meine Praxis befindet sich in einem der Neubaugebiete in Erkelenz, nahe der B56.
                            Sie finden mich im Frankenring 114. Parkmöglichkeiten sind in der Straße zur Genüge vorhanden.
                            Die Praxis grenzt direkt an einen abgeschlossenen Garten an. So bietet sie eine angenehme geborgene
                            Atmosphäre und gleichzeitig das Gefühl, direkt hinaus ins Grüne treten zu können. Dies wird durch
                            die große Fensterfassade verstärkt.</p>

                        <h4>Kontakt</h4>
                        <ul>
                            <li>Telefonisch</li>
                            <ul>
                                <li>Unter der Woche immer fünf Minuten <br/> vor jeder vollen Stunde zwischen 08:00 und 19:00</li>
                                <li>Samstags zwischen 10:00 und 14:00</li>
                            </ul>
                            <li>Per Mail</li>
                            <ul>
                                <li>Jederzeit via <a href="mailto:krauseerkelenz@t-online.de">krauseerkelenz@t-online.de</a></li>
                            </ul>
                        </ul>

                        <h4>Wichtige Informationen <br/> & Aktuelles</h4>
                        <p> Aufgrund der aktuellen Lage in Bezug auf COVID-19 möchten wir hier noch einmal explizit
                            auf die Möglichkeit der Videosprechstunde hinweisen. Wir nutzen den Anbieter <a href="https://www.redmedical.de/red-connect-videosprechstunde/">Red Medical</a> welcher sicherstellt,
                            dass Ihre Daten jederzeit verschlüsselt sind. Wir bitten Sie außerdem,
                            auf Händeschütteln zur Begrüßung und Verabschiedung zu verzichten. Des Weiteren bitten wir um ein Einhalten der Niesetikette.</p>
                        

                    </div>

                    <div className="images">
                        <img className ="image" src={welcome} alt="Praxis"></img>
                    </div>
                    <br/>
                    <div className="Copyright">
                        <h4>Adresse:</h4>
                        <div className="Map" dangerouslySetInnerHTML={this.iframe(frame)}/>
            
                    </div>
                </div> 
            </div>
        </HashRouter>);
    }
}
export default Home;