import React, { Component } from "react";
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";
import Home from "./Home";
import { Navbar, Nav, NavItem, NavDropdown, MenuItem} from 'react-bootstrap';





class Main extends Component {

  constructor(props){
    super(props);
  }


  render() {
    return (
      <HashRouter>
        <div>
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Navbar.Brand href="/">Psychotherapeutische Praxis Andreas Krause</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">

                  <NavItem>
                    <NavLink className ="nav-link" exact to="/">HOME</NavLink>
                  </NavItem>
                  
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div id="content" className="content container">
            <Route exact path ="/" component={() => <Home/>}></Route>
          </div>
        </div>
      </HashRouter>
    );
  }
}

export default Main;